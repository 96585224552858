<template>
    <b-card>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
        <div v-if="blog.id">
            <form @submit.prevent="itemEdited" id="edit-item">
                <b-form-group >
                  <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                    <b-form-input id="title" name="title" v-model="blog.title" :disabled="disabled"></b-form-input>
                </b-form-group>
                <b-form-group label="خلاصه خبر" label-for="lead">
                    <b-form-textarea id="lead" name="lead" data-required v-model="blog.lead" :disabled="disabled"></b-form-textarea>
                </b-form-group>
                <b-form-group >
                  <label for="body"> متن خبر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                    <b-form-textarea id="body" rows="5" data-required v-model="blog.body" :disabled="disabled"></b-form-textarea>
                </b-form-group>
                <div class="row">
                  <div class="col-md-4">
                    <b-form-group >
                      <label for="category_id"> دسته بندی <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                      <b-form-select id="category_id" name="category_blog_id" v-model="blog.category_blog_id" :disabled="disabled">
                        <b-form-select-option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.name}}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group >
                      <label for="image"> بارگذاری تصویر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                      <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <label class="custom-switch" style="margin-top: 40px;">
                      <input type="checkbox" name="status" class="custom-switch-input" v-model="blog.status" :disabled="disabled">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">وضعیت</span>
                    </label>
                  </div>
                </div>
               
                
                <button class="btn btn-success" type="submit" :disabled="disabled">ویرایش خبر</button>
                <input type="hidden" name="_method" value="PUT">
            </form>
        </div>
    </b-card>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/blogs',
      blog: {},
      title: 'ویرایش خبر',
      categories: []
    }
  },
  computed: {
    id () {
      return this.$route.params.blog
    }
  },
  methods: {
    loadCategories () {
      this.$axios.get(this.$root.baseUrl + '/api/admin/blog-categories?all=true')
      .then(response => {
        this.categories = response.data.data
      })
    },
    itemEdited() {
        this.disabled = true
        const body = window.CKEDITOR.instances.body.getData()
        let form = document.getElementById('edit-item')
        let formData = new FormData(form)
        formData.append('body', body)
        this.$axios.post(this.$root.baseUrl + this.url + `/${this.blog.id}`, formData)
          .then(response => {
            this.$root.success_notification('خبر با موفقیت ویرایش شد')
            this.$router.push('/admin/blogs')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
  },
  mounted () {
    if (this.id) {
      this.show = true
      this.$axios.get(this.$root.baseUrl + this.url + `/${this.id}`)
        .then(response => {
          this.blog = response.data.data
          this.title = this.blog.title
           this.loadCkeditor()
            this.show = false
        })
      
    }
  },
  created () {
    this.loadCategories()

    // window.blogEdited = (response) => {
    //   if (response.success) {
    //     this.success_notification('خبر با موفقیت ویرایش شد')
    //     this.$router.push('/admin/blogs')
    //   }
    // }
  }
}
</script>
